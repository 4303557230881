import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
  {
    
    path: '/login',
    name: 'login',
    component: () => import("../login.vue"),
  },
  {
    path: '/',
    name: 'home',
    component: () => import("../views/index.vue"),
    redirect: "/newsManager/list",
    children: [
      {
        path: '/newsManager/list',
        name: 'newsManager_list',
        component: () => import("../views/newsManager/list.vue"),
        children: [

        ]
      },
      {
        path: '/newsManager/add',
        name: 'newsManager_add',
        component: () => import("../views/newsManager/add.vue"),
        children: [

        ]
      },
      {
        path: '/newsManager/edit',
        name: 'newsManager_edit',
        component: () => import("../views/newsManager/edit.vue"),
        children: [

        ]
      },{
        path: '/classManager/list',
        name: 'classManager_list',
        component: () => import("../views/classManager/list.vue"),
        children: [

        ]
      },
      {
        path: '/classManager/add',
        name: 'classManager_add',
        component: () => import("../views/classManager/add.vue"),
        children: [

        ]
      },
      {
        path: '/classManager/edit',
        name: 'classManager_edit',
        component: () => import("../views/classManager/edit.vue"),
        children: [

        ]
      },
      {
        path: '/userManager/list',
        name: 'userManager_list',
        component: () => import("../views/userManager/list.vue"),
        children: [

        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
